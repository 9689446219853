<template>
    <div class="MBvideo">
        <div v-if="loadingState.value === 'loading'">Loading</div>
        <div :id="'parent-iframe'+videoId" v-else>
            <iframe ref="video" scrolling="no" :id="'iframe'+videoId" width=100% height=100% :data-src="widgetSrc" v-on:load="iframeloaded"></iframe>
        </div>
    </div>
</template>

<script>
import {watchEffect, ref, onMounted} from 'vue'
    import { useRouter } from 'vue-router'
    import { loadVideo } from "../../service/api"
    export default {
        name: "VideoView",
        props: {
            videoId: {
                type: String
            }
        },
        setup() {
            const video = ref(null)
            const router = useRouter()
            const otp = ref(null) // use const because you dont want to lose this value
            const loadingState = ref('loading')
            const widgetSrc = ref('');

            const fetchOtp = videoId => {
                loadingState.value = 'loading'
                return loadVideo(videoId)
                    .then(response => {
                        loadingState.value = 'success'
                        otp.value = response.data.data.otp

                        let src = '/lib/vdociper/widget.html?'+
                            'video_id='+videoId+'&'+
                            'playback_info='+response.data.data.playbackInfo+'&'+
                            'otp='+response.data.data.otp;
                        widgetSrc.value = src;
                      window.observer.observe(video.value)

                    })
                    .catch(error => {
                        //todo add error view
                      console.log(error)
                        if (error.response.status === 404) {
                            router.push("/404")
                        }
                        loadingState.value = 'error'
                    })
            }

            return { loadingState, fetchOtp, widgetSrc, video }

        },
        created() {
            this.fetchOtp(this.videoId)

        },
        methods: {
            iframeloaded() {

              // var iframe = document.getElementById("iframe"+this.videoId);
              //
              //   // Adjusting the iframe height onload event
              //   iframe.onload = function(){
              //     setTimeout( function() {
              //       // iframe.height = iframe.contentWindow.document.body.scrollHeight + 10
              //       iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 10 + 'px';
              //     }, 1000);
              //   }
            }
        }
    }
</script>

<style scoped>
    .MBvideo {
        width: 100%;


        margin-bottom: 3em;
        position: relative;
    }
</style>