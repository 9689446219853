<template>
    <KCourse loader-class="MBcont" course-id="4" class="MBcont">
        <div class="MBtextcont">
            <h1>Создание костюма XIXв.</h1>
            <p>Нас с вами ждет очень творческий и насыщенный процесс создания костюма начала XIX века в стиле ампир.</p>
            <p>Все необходимые материалы можно <a target=”_blank” href="https://docs.google.com/document/d/1X1un_-ALesvIrrVVxEmOrlEzzROwtAre5XtLnSPL0CY/edit?usp=sharing"> найти тут</a></p>
            
            <h2>1-ый шаг. Подготовка</h2>
            <p>Девочки, у вас есть время, чтобы определиться с образом и цветотипом вашей куколки. В помощь выкладываю вам урок из марафона по росписи, который отлично поддерживает тему костюма и образа. Смотреть до 20-ой минуты.</p>
            <p class="nomargin"><b>С чем необходимо определиться до начала:</b></p>
            <ol>
                <li><a target=”_blank” href="https://color.romanuke.com/">Цветовая схема костюма</a>  (главный цвет и второстепенные)</li>
                <li>Составить коллаж из тканей, кружав, соединив всё в единую картину вместе с куклой )</li>
            </ol>
        </div>

        <VideoView video-id="d9cdb23f53264e349b4746fae85ce433"/>
       
        <div class="MBtextcont">
            <p>Небольшой гайд о том, как выбирать ткани</p>
        </div>

         <VideoView video-id="933740148ea44efebd3da6d0e65586d2"/>

        <div class="MBtextcont">
            <h2>1-ый шаг. Снимаем выкройку</h2>
            <p>В этом уроке мы с вами разберем, как аккуратно снять выкройку с куклы и как ее смоделировать. Т.е. результатом этого занятия должны быть готовые бумажные выкройки для панталон, подъюбника, нижнего и верхнего платья.</p>
            
            <h3>1.0 Введение</h3>
        </div>

        <VideoView video-id="1a59a1f5bdc74ece9745b0e2e9eb4588"/>

        <div class="MBtextcont">
            <h3>1.1 Создаем базу выкройки</h3>
        </div>
        
        <VideoView video-id="dc74ad8e35e440d089b51e6b7c50bf3c"/>

        <div class="MBtextcont">
            <h3>1.2 Переносим выкройку на бумагу</h3>
        </div>
        
        <VideoView video-id="79115c0cf52e4c36a13cab840d928d16"/>

        <div class="MBtextcont">
            <h3>1.3 Моделируем юбку</h3>
        </div>
        
        <VideoView video-id="1c4ed91bc1624c41a35cff4b3aef464e"/>

        <div class="MBtextcont">
            <h3>1.4 Моделируем панталоны</h3>
        </div>
        
         <VideoView video-id="423fa5b19a874060bb702187e088e6d6"/>

        <div class="MBtextcont">
            <h3>1.5 Моделируем верхнее платье</h3>
        </div>
        
        <VideoView video-id="6e6c6dfb02594aad8959ad3e5398f793"/>

        <div class="MBtextcont">
            <h3>1.5 Моделируем 4 вида рукавов</h3>
            <p>Бонусное занятие</p>
        </div>
        
        <VideoView video-id="e031a068b1ac4e76881d5cd1f434c8c8"/>

        <div class="MBtextcont">
            <h2>2-ой шаг. Раскрой ткани</h2>
            <p>Сегодня нас ждет раскрой ткани. Работы будет много… Если вы не смогли найти нужный цвет ткани, лент, то смотрите видео по покраске ))</p>
            <h3>2.1 Подбираю ткань, кружева</h3>
        </div>

        <VideoView video-id="c84833dc77334a16abc479965e079a69"/>

        <div class="MBtextcont">
            <h3>2.2 Покраска ткани, лент</h3>
        </div>

        <VideoView video-id="3b92f5450a55448ca12956185509491f"/>

        <div class="MBtextcont">
            <h3>2.3 Раскрой ткани</h3>
        </div>

        <VideoView video-id="773cac55a36b4812ad75d74ebc9db9ca"/>

        <div class="MBtextcont">
            <h2>3-ий шаг. Шьем панталоны и подъюбник</h2>
            <p>Мы начинаем шить. Вот он и наступил этот немного волнительный момент! Скоро и он будет пройден, и ваши усилия обязательно воплотятся в маленький чудесный наряд ))) А начнём мы с самого простого с панталон и подъюбника.</p>
            <p class='nomargin'>В уроке есть 2 вида обработки пояса панталон:</p>
            <ol>
                <li>Вариант на резинке. Отлично подойдёт для начинающих.</li>
                <li>Вариант с поясом. Посложнее.</li>
            </ol>
            <p>Выбирайте по своим силам и задачам. <a v-scroll-to="'#top10'">А в этом разделе</a> вас ждут небольшие уроки по швам и утюжке для начинающих. Смотрите, изучайте, применяйте и, конечно, делитесь результатами!</p>
            <h3>3.1 Панталоны. Делаем основу</h3>
        </div>

        <VideoView video-id="77307857da1243c3bb61a8d475561580"/>

        <div class="MBtextcont">
            <h3>3.2 Панталоны на поясе</h3>
        </div>

        <VideoView video-id="9562ae677974426f977a4f67ecc34e73"/>

        <div class="MBtextcont">
            <h3>3.3 Панталоны на резинке</h3>
        </div>

        <VideoView video-id="5c5335ca778e49058e5826a365984e1d"/>

        <div class="MBtextcont">
            <h3>3.4 Нижняя юбка</h3>
        </div>

        <VideoView video-id="5d4526d46c99485b934e1b19461979ce"/>

        <div class="MBtextcont">
            <h3>3.5 Бонусное занятие по пошиву чулок</h3>
        </div>

        <VideoView video-id="6f85a00652ed4b499bdb43d4108fda36"/>

        <div class="MBtextcont">
            <h2>4-ый шаг. Шьем полочку платья</h2>
            <p>Сегодня мы начинаем шить платье. Этот урок будет по полочке. Процесс ответственный, многое нужно учесть. В уроке есть два варианта обработки горловины, с подкладом и без. Пробуйте и выбирайте свой вариант.</p>
            <h3>4.1 Вытачки и плечевой шов</h3>
        </div>

        <VideoView video-id="5d4526d46c99485b934e1b19461979ce"/>

        <div class="MBtextcont">
            <h3>4.2 Если шьете без подклада</h3>
        </div>

        <VideoView video-id="394b7c9e2c2e440d9ce1441adf8ce183"/>

        <div class="MBtextcont">
            <h3>4.3 Рукав</h3>
        </div>

        <VideoView video-id="e5924d5e8cde4f7faea5c0e19abd4d07"/>

        <div class="MBtextcont">
            <h3>4.4 Подклад для полочки</h3>
        </div>

        <VideoView video-id="478bc4b7ea6b4af69f6deb055f85f126"/>

        <div class="MBtextcont">
            <h2>5-ый шаг. Дошиваем платье</h2>
            <p>На этом уроке мы закончим работу с платьем. Готовы увидеть свою девочку в наряде? Я очень хочу увидеть ваши работы ))</p>
            <h3>5.1 Шьем подол платья</h3>
        </div>

        <VideoView video-id="39a3d6c1af0446a0af4e5081a320da82"/>

        <div class="MBtextcont">
            <h3>5.2 Собираем платье</h3>
        </div>

        <VideoView video-id="9253486b4ae84b62b00c60cbda452b07"/>

        <div class="MBtextcont">
            <h3>5.3 Декорируем платье</h3>
        </div>

        <VideoView video-id="79b40f8deb014f4b82ce68e11e3b8cac"/>

        <div class="MBtextcont">
            <h2>6-ой шаг. Шьем красное платье-шлейф</h2>
            <p>Мы почти уже у цели. Ловите следующее занятие )</p>
            <h3>6.1 Шьем красную полочку</h3>
        </div>

        <VideoView video-id="74731546e81448c186184b67165d6647"/>

        <div class="MBtextcont">
            <h3>6.2 Шьем красный подол</h3>
        </div>

        <VideoView video-id="e983c1c4802f42f0948b6ed35335cfeb"/>

        <div class="MBtextcont">
            <h3>6.3 Соединяем полочку с подолом</h3>
        </div>

        <VideoView video-id="69295cb7fd13489ba5209c39ed5c0bd4"/>

        <div class="MBtextcont">
            <h3>6.4 Плессированный воротник</h3>
        </div>

        <VideoView video-id="c3ef8ae736e04f9f83eee59ef29086b5"/>

        <div class="MBtextcont">
            <h2>7-ой шаг. Делаем обувь</h2>
            <p>Дорогие друзья, это последнее занятие марафона по ботиночкам. Мы закончили с вами достаточно долгий и кропотливый процесс создания костюма для наших кукол. Я как всегда жду ваших работ!</p>
            <h3>7.1 Делаем основу туфелек</h3>
        </div>

        <VideoView video-id="ea0b8e05937c419f8011887beb1ab4ed"/>

        <div class="MBtextcont">
            <h3>7.2 Подошва, каблук, кант</h3>
        </div>

        <VideoView video-id="05921306192c4e6983963cb64f60d4fc"/>

        <div class="MBtextcont">
            <p>Знания без практики сами по себе ничего не значат, поэтому я очень благодарна всем, кто сделал свою куклу вместе с нами! Теперь вы владеете бесценным опытом и вдохновением от собственного результата ) Очень важно именно сейчас не расплескать этот настрой, этот творческий поток и начать двигаться дальше )</p>
        </div>
        
        <div class="MBtextcont">
            <h2 id="top10">ТОП 10 строчек для шитья + утюжка</h2>
            <p>Мой топ 10 строчек, которые я используют при шитье костюма для кукол.</p>
            <h3>Ручные строчки</h3>
            <p>Ручной <b>шов назад иголку,</b> аналог машинной строчки.</p>
        </div>

        <VideoView video-id="718d5dcaf1284c92a4921b2dc0e0fe8f"/>

        <div class="MBtextcont">
            <p><b>Обметочный </b>ручной шов через край. Название говорит само за себя.</p>
        </div>

        <VideoView video-id="fd00654a44bb479e93fd856a0cc4a500"/>

        <div class="MBtextcont">
            <p><b>Ручной шов в сгиб или матрацный</b>. Отлично соединяет края изделия.</p>
        </div>

        <VideoView video-id="876ae7afaeef48b4aae681a1634a8e18"/>
        
        <div class="MBtextcont">
            <p><b>Потайной </b>ручной шов, в основном для обработки нижнего среза изделия или подола.</p>
        </div>

        <VideoView video-id="106a9dc9c61d4a82b5f7c145c7e63be0"/>

        <div class="MBtextcont">
            <p><b>Воздушная петля,</b> для застёжки на пуговицу.</p>
        </div>

        <VideoView video-id="f055faa0180d4eb9a87d24864e1dfd70"/>

        <div class="MBtextcont">
            <h3>Машинные строчки</h3>
            <p><b>Сборка.</b> Быстрая сборка оборок.</p>
        </div>

        <VideoView video-id="cc8521fd28ab4e228536d14e575de8a1"/>

        <div class="MBtextcont">
            <p>Простой машинный <b>шов с закрепкой.</b></p>
        </div>

        <VideoView video-id="50cc43e7cfda40968871bc39efe0eefc"/>

        <div class="MBtextcont">
            <p>Машинный <b>двойной шов</b> для обработки среза тонких тканей</p>
        </div>

        <VideoView video-id="99e16b473f704c3d9e4822fd21013f6d"/>

        <div class="MBtextcont">
            <p>Машинный шов <b>зигзаг</b>, для обработки среза.</p>
        </div>

        <VideoView video-id="be5e0d970c714f758518bfa06fda4f87"/>

        <div class="MBtextcont">
            <p>Машинный шов <b>рулик</b>, или шов в подгибку с открытым срезом.</p>
        </div>

        <VideoView video-id="48ec9f9c9d1a452880ca006aef384583"/>

        <div class="MBtextcont">
            <h3>Утюжка</h3>
        </div>

        <VideoView video-id="eca15332736f40a8a2c119a2b77e878d"/>

    </KCourse>
</template>

<script>
    import VideoView from "../Widgets/VideoView";
    import KCourse from "../Widgets/KCourse";
    export default {
        components: {
            KCourse,
            VideoView
        }
    }
</script>

<style>
    .MBcont {
        max-width: 1100px;
        margin: 0 auto;
        border-top: 1px solid #cbcbcb;
        padding: 30px;
        min-height: 300px;
        position: relative;
    }

    .MBcont h1 {
        font-size: 35pt;
        line-height: 45pt;
        text-align: left;
        margin: 0 0 20px 0;
        font-weight: normal;
    }
    .MBcont .subtitle {
        position: relative;
        top: -25px;
        font-size: 10pt;
    }

    .MBtextcont {
        max-width: 550px;
        margin: 0 auto 15pt auto;
        font-size: 12pt;
        line-height: 22pt;
    }

    .MBtextcont h1 {
        margin-top: 20pt;
    }

    .MBtextcont h2 {
        font: 600 16pt/20pt "Montserrat", arial, sans-serif;
        text-align: left;
        margin: 40pt 0 15pt 0;
    }

    .MBtextcont ul {
        list-style: disc;
    }

    .MBtextcont ul,
    .MBtextcont ol {
        list-style: disc;
        margin: 0;
        padding-left: 40pt;
    }

    .MBtextcont ul li,
    .MBtextcont ol li {
        margin-bottom: 0pt;
    }

    .MBtextcont p {
        margin-bottom: 15pt;
        margin-top: 0;
    }

    .MBtextcont p.nomargin {
        margin: 0pt;
    }

    .MBback {
        position: absolute;
        top: 0;
        left: 0;
    }

    /* .MBtextcont .a-download {
            padding: 5px 5px 5px 25px;
            background: url('/img/membersh/MB-icons.svg') no-repeat;
            background-size: 20px;
            background-position: 2px -75px;
        } */

    .MBtextcont h3 {
        font: 600 12pt/20pt "Montserrat", arial, sans-serif;
        text-align: left;
        margin: 0;
    }

    .MBtextcont a::after {
        content: "";
        border-top: 1px solid #b23642;
        position: absolute;
        width: 100%;
        left: 0;
        right: 100%;
        bottom: -3px;
        transition: width 0.4s ease-out;
    }

    .MBtextcont a:hover::after {
        width: 0;
    }
</style>